import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import img from "../assets/logo.png";
import "./root.css";
const Root = () => {
  const [isOpen, isOpen_set] = useState(false);
  return (
    <>
      <header>
        <nav>
          <div className="container-img">
            <img src={img} alt="logo" />
          </div>

          <div className="container-hambuerger" onClick={()=>isOpen_set((v)=>!v)}>
            <svg
              width="38"
              height="26"
              viewBox="0 0 38 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.25 25.5V21.3333H37.75V25.5H0.25ZM0.25 15.0833V10.9167H37.75V15.0833H0.25ZM0.25 4.66667V0.5H37.75V4.66667H0.25Z"
                fill="white"
              />
            </svg>
          </div>

          <div className={`mobile-header ${isOpen ? "open" : ""}`}>
            <ul>
              <li>
                <Link to="/" className="link" onClick={()=>isOpen_set((v)=>!v)}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/chi-sono" className="link" onClick={()=>isOpen_set((v)=>!v)}>
                  Chi Sono?
                </Link>
              </li>
              <li>
                <Link to="/progetti" className="link" onClick={()=>isOpen_set((v)=>!v)}>
                  Progetti
                </Link>
              </li>
              <li>
                <Link to="/lavori" className="link" onClick={()=>isOpen_set((v)=>!v)}>
                  Lavori
                </Link>
              </li>
            </ul>

            <button className="contact-btn">
              <span>Contattami</span>
            </button>
          </div>

          <ul>
            <li>
              <Link to="/" className="link">
                Home
              </Link>
            </li>
            <li>
              <Link to="/chi-sono" className="link">
                Chi Sono?
              </Link>
            </li>
            <li>
              <Link to="/progetti" className="link">
                Progetti
              </Link>
            </li>
            <li>
              <Link to="/lavori" className="link">
                Lavori
              </Link>
            </li>
          </ul>

          <button className="contact-btn">
            <span>Contattami</span>
          </button>
        </nav>
      </header>
      <Outlet />
    </>
  );
};

export default Root;
