import React from "react";
import chisonoprimaiommagine from "../assets/chisonoprimaiommagine.jpg";
import "./chiSono.css";

const ChiSono = () => {
  return (
    <>
      <main>
        <h2>Chi sono</h2>
        <hr />
        <section className="chisono">
          <div className="periodo">
            <div className="periodo__img">
              <img src={chisonoprimaiommagine} alt="" />
            </div>
            <div className="periodo__info">
              <h3>Periodo: 2023 - attuale</h3>
              <h4>
                Percorso di studi: <span>ITS Software Architect</span>
              </h4>
              <h4>Progetti:</h4>
              <ul>
                <a href="./clicktest" target="_blank" >
                  <div className="link__progetto">
                    <div className="link__svg">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.5748 13.4282C17.2095 12.0635 15.3582 11.2969 13.4278 11.2969C11.4975 11.2969 9.64613 12.0635 8.28088 13.4282L3.13228 18.5751C1.767 19.9404 1 21.7921 1 23.7229C1 25.6537 1.767 27.5054 3.13228 28.8707C4.49755 30.2359 6.34926 31.0029 8.28005 31.0029C10.2108 31.0029 12.0625 30.2359 13.4278 28.8707L16.0013 26.2972"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M13.4248 18.5748C14.7901 19.9394 16.6414 20.7061 18.5717 20.7061C20.5021 20.7061 22.3534 19.9394 23.7187 18.5748L28.8673 13.4278C30.2326 12.0625 30.9996 10.2108 30.9996 8.28005C30.9996 6.34926 30.2326 4.49755 28.8673 3.13228C27.502 1.767 25.6503 1 23.7195 1C21.7887 1 19.937 1.767 18.5717 3.13228L15.9983 5.70574"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <li>Click Test</li>
                  </div>
                </a>
                <a href="#" rel="noopener noreferrer">
                  <div className="link__progetto">
                    <div className="link__svg">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.5748 13.4282C17.2095 12.0635 15.3582 11.2969 13.4278 11.2969C11.4975 11.2969 9.64613 12.0635 8.28088 13.4282L3.13228 18.5751C1.767 19.9404 1 21.7921 1 23.7229C1 25.6537 1.767 27.5054 3.13228 28.8707C4.49755 30.2359 6.34926 31.0029 8.28005 31.0029C10.2108 31.0029 12.0625 30.2359 13.4278 28.8707L16.0013 26.2972"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M13.4248 18.5748C14.7901 19.9394 16.6414 20.7061 18.5717 20.7061C20.5021 20.7061 22.3534 19.9394 23.7187 18.5748L28.8673 13.4278C30.2326 12.0625 30.9996 10.2108 30.9996 8.28005C30.9996 6.34926 30.2326 4.49755 28.8673 3.13228C27.502 1.767 25.6503 1 23.7195 1C21.7887 1 19.937 1.767 18.5717 3.13228L15.9983 5.70574"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <li>In arrivo</li>
                  </div>
                </a>
              </ul>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ChiSono;
