import './button.css';

function Button({text, isVisible}){
    const buttonStyle = {
        visibility: isVisible ? 'visible' : 'hidden'
    };
    return(
        <button style={buttonStyle}>
            <span>{text}</span>
        </button>
    );
}
export default Button;