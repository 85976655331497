import React from "react";
import "./App.css";
import WorksCard from "./components/worksCard/Workscard";
import backsite from "../assets/background-site.png";
import database from "../assets/databasecovercard.png";
import webapp from "../assets/webappcovercard.png";
import ContactForm from "./components/contactForm/contactForm";
import Header from "./components/header/Header";
import ImgClickTest from "../assets/project-click-test.png";
import IconShare from "../assets/icon/share.png";
import { Link } from "react-router-dom";

function App() {
  return (
    <>
      <Header />
      <main>
        <section className="service">
          <h2>I miei Servizi</h2>
          <div className="container-card">
            <WorksCard
              urlImg={webapp}
              title={"Web App"}
              description={
                "Dagli obiettivi dell’app fino alla completa implementazione"
              }
              isButton={true}
            />
            <WorksCard
              urlImg={database}
              title={"Database"}
              description={
                "Dal capire le necessita’ del cliente fino alla sua realizzazione"
              }
              isButton={false}
            />

            <WorksCard
              urlImg={backsite}
              title={"Siti Vetrina"}
              description={
                "Partendo dal design, ricerca seo piu’ implementazione e pubblicazione online"
              }
              isButton={true}
            />
          </div>
        </section>
        <section className="progetti">
          <h2>I miei Progetti</h2>
          <div className="container-progetti">
            <Link to="/Progetti" className="container-project">
              <div className="container-svg">
                <svg
                  width="800px"
                  height="800px"
                  viewBox="-0.5 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.47 4.13998C12.74 4.35998 12.28 5.96 12.09 7.91C6.77997 7.91 2 13.4802 2 20.0802C4.19 14.0802 8.99995 12.45 12.14 12.45C12.34 14.21 12.79 15.6202 13.47 15.8202C15.57 16.4302 22 12.4401 22 9.98006C22 7.52006 15.57 3.52998 13.47 4.13998Z"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="progetto__thumbnail">
                <img
                  src={ImgClickTest}
                  alt="Thumbnail del progetto click test"
                />
              </div>
            </Link>
          </div>
        </section>
        {/* <section className="contattami">
          <h2>Contattami</h2>
          <ContactForm/>
        </section> */}
      </main>
    </>
  );
}

export default App;
