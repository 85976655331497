import React from "react";
import "./progetti.css";
import ImgClickTest from "../assets/project-click-test.png";

const Progetti = () => {
  return (
    <>
      <main>
        <h2>I miei Progetti</h2>
        <hr />
        <section className="container__progetti">
          <div className="progetto" id='clicktest'>
            <div className="info__progetto">
              <div className="title">
                <span>Click Test</span>
              </div>
              <div className="descrizione">
                <span>Obiettivi:</span>
                <span>
                  Questo progetto ha come scopo principale l'esercitazione
                  dell'utilizzo di: react routes, chiamate al database, file
                  hook e passaggi di parametri. La sezione "Leaderboard"
                  aggiorna i dati ogni volta che verra' selezionata una
                  modalita', dopo aver selezionato la modalita' si puo'
                  procedere al test cliccando ripetutamente nell'area sotto le
                  statistiche del test. In caso l'utente voglia registrare i
                  propri risultati, lo potra' fare o registrandosi oppure
                  accedendo al sito.
                </span>
              </div>
              <div className="link">
                <ul>
                  <li>
                    <a href="https://github.com/da4do0/clickTestTailwind" target="_blank">
                      <div className="link__svg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 24 24"
                          fill="white"
                        >
                          <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                        </svg>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="https://da4do0.github.io/clicktestpages/" target='_black'>
                      <div className="link__svg">
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.5748 13.4282C17.2095 12.0635 15.3582 11.2969 13.4278 11.2969C11.4975 11.2969 9.64613 12.0635 8.28088 13.4282L3.13228 18.5751C1.767 19.9404 1 21.7921 1 23.7229C1 25.6537 1.767 27.5054 3.13228 28.8707C4.49755 30.2359 6.34926 31.0029 8.28005 31.0029C10.2108 31.0029 12.0625 30.2359 13.4278 28.8707L16.0013 26.2972"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M13.4248 18.5748C14.7901 19.9394 16.6414 20.7061 18.5717 20.7061C20.5021 20.7061 22.3534 19.9394 23.7187 18.5748L28.8673 13.4278C30.2326 12.0625 30.9996 10.2108 30.9996 8.28005C30.9996 6.34926 30.2326 4.49755 28.8673 3.13228C27.502 1.767 25.6503 1 23.7195 1C21.7887 1 19.937 1.767 18.5717 3.13228L15.9983 5.70574"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="img__progetto">
              <img src={ImgClickTest} alt="Thumbnail del progetto click test" />
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Progetti;
