import React from "react";
import './contactForm.css';

function ContactForm(){
    //TODO finire parte js form
    return(
        <>
            <div className="form-container">
                <div className="form">
                    
                </div>
            </div>
        </>
    )
}

export default ContactForm;