import React from "react";
import CryMeme from "../assets/crying.jpg";
import "./lavori.css";

const Lavori = () => {
  return (
    <>
      <main>
        <h2>Siti venduti</h2>
        <hr />
        <section className="container__siti">
          <div className="container__text">
            <h3>Vuoi un sito?</h3>
            <button>
              <span>Contattami</span>
            </button>
          </div>
          <div className="container__meme">
            <img src={CryMeme} alt="" />
          </div>
        </section>
      </main>
    </>
  );
};

export default Lavori;
