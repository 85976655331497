import React from 'react'
import ReactDOM from 'react-dom/client'
import {createBrowserRouter,RouterProvider} from "react-router-dom";
import './index.css';
import Root from './routes/root';
import App from './routes/App';
import Progetti from './routes/progetti';
import Lavori from './routes/lavori';
import ChiSono from './routes/chiSono';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root/>,
    children:[
      {
        index: true,
        element: <App/>
      },
      {
        path: "/progetti",
        element: <Progetti/> 
      },
      {
        path: "/lavori",
        element: <Lavori/> 
      },
      {
        path: "/chi-sono",
        element: <ChiSono/> 
      },
    ]
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)
