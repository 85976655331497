import Button from '../../components/button/button';
import './workscard.css';

function WorksCard({urlImg, title, description, isButton}){
    return(
        <div className="card">
            <div className="img-background">
                <img src={urlImg} alt="background card website"/>
            </div>
            <div className="container-txt">
                <h3>{title}</h3>
                <p>{description}</p>
                <Button
                    text={"Contattami"}
                    isVisible={isButton}
                /> 
            </div>
        </div>
    );
}

export default WorksCard;

